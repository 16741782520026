var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"loading":_vm.isLoading,"paginated":_vm.paginated,"per-page":_vm.perPage,"striped":true,"default-sort":_vm.defaultSort,"data":_vm.rows}},[_c('b-table-column',{attrs:{"label":"管理番号","width":"10em","field":"code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"氏名","width":"12em","field":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"エリア","field":"areaSortVal","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.area ? row.area.label : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"label":"権限","field":"roleSortVal","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.role ? row.role.label : '-')+" ")]}}])}),_c('b-table-column',{attrs:{"label":"ステータス","width":"8em","field":"activeSortVal","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v("有効")]):_c('b-tag',{attrs:{"type":"is-dark"}},[_vm._v("無効")])]}}])}),_c('b-table-column',{attrs:{"label":"備考","field":"note","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.note)+" ")]}}])}),_c('b-table-column',{attrs:{"custom-key":"actions","cell-class":"is-actions-cell"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"buttons is-right"},[(row.isShop)?_c('b-button',{attrs:{"type":"is-warning","size":"is-small","icon-left":"key"},on:{"click":function($event){return _vm.$emit('willEditShopPassword', row)}}}):_c('b-button',{attrs:{"type":"is-warning","size":"is-small","icon-left":"square-edit-outline"},on:{"click":function($event){return _vm.$emit('willEdit', row)}}})],1)]}}])}),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v("Fetching data...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing's here…")])]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }