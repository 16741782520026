<template>
  <CardComponent :title="cardTitle" icon="ballot" style="width: 800px">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(willSave)">
        <b-field
          label="管理番号"
          message="ログインID／パスワード初期値として利用されます。半角英数字4文字以上必要です。"
          horizontal
        >
          <ValidationInput
            v-model="userForm.code"
            rules="required|alpha_num|min:4|max:10"
            :autofocus="isCreate"
          ></ValidationInput>
        </b-field>
        <b-field label="氏名" horizontal>
          <ValidationInput
            v-model="userForm.name"
            rules="required|max:10"
          ></ValidationInput>
        </b-field>
        <b-field label="エリア" class="has-check" horizontal>
          <EnumPicker
            :members="AREA_OPTIONS"
            noneLabel="全て"
            :grouped="true"
            :grouped-multiline="true"
            v-model="userForm.area"
          />
        </b-field>
        <b-field label="備考" horizontal>
          <b-input
            type="textarea"
            v-model="userForm.note"
            maxlength="500"
            :rows="2"
          />
        </b-field>
        <hr />
        <div v-if="isSelf">
          <b-field label="権限" horizontal message="自身の情報は変更できません">
            <small></small>
          </b-field>
        </div>
        <div v-else>
          <b-field label="権限" class="has-check" horizontal>
            <EnumPicker
              :members="ROLE_OPTIONS"
              :grouped="true"
              :grouped-multiline="true"
              v-model="userForm.role"
            />
          </b-field>
          <b-field label="ステータス" horizontal v-if="!isCreate">
            <b-switch v-model="userForm.active"> 有効 </b-switch>
          </b-field>
          <b-field
            label="パスワード"
            horizontal
            message="パスワードを管理番号にリセットします"
            v-if="mUser"
          >
            <b-button
              size="is-small"
              type="is-warning"
              :loading="isLoading"
              @click="willResetPassword"
              >リセット</b-button
            >
          </b-field>
        </div>
        <hr />
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button
                native-type="submit"
                type="is-primary"
                :loading="isLoading"
                >登録</b-button
              >
            </div>
            <div class="control">
              <b-button
                type="is-primary is-outlined"
                @click="$emit('close')"
                :loading="isLoading"
                >キャンセル</b-button
              >
            </div>
          </b-field>
        </b-field>
      </form>
    </ValidationObserver>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import EnumPicker from '@/components/EnumPicker.vue'
import { MUser, UserForm } from '@/types/typeUser'
import { Enums } from '@/types/enum'
import { ApiPassword, ApiUser } from '@/api'
import { mapState } from 'vuex'
export default {
  mixins: [BaseMixin],
  components: { CardComponent, EnumPicker },
  props: {
    mUser: {
      type: MUser,
      default: null
    }
  },
  computed: {
    ROLE_OPTIONS: () => [Enums.Role.Admin, Enums.Role.Stuff, Enums.Role.Limited],
    AREA_OPTIONS: () => Enums.Area.list,
    ...mapState('storeAuth', ['authUser']),
    cardTitle () {
      return this.mInstructor ? 'アカウント編集' : 'アカウント新規作成'
    },
    isCreate () {
      return !this.mUser
    },
    isSelf () {
      return this.mUser && this.mUser.id === this.authUser.id
    },
  },
  data () {
    return {
      userForm: new UserForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiUser.saveUser(this.userForm).then(({ mUser }) => {
          this.$emit('saved', mUser)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
    willResetPassword () {
      console.log('willResetPassword')
      if (!this.mUser) {
        return
      }
      this.isLoading = true
      setTimeout(() => {
        ApiPassword.resetPassword(this.mUser.id).then(() => {
          this.$emit('reseted')
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    }
  },
  mounted () {
    if (this.mUser) {
      this.userForm.fill(this.mUser)
    }
    if (this.mUser && this.mUser.isShop) {
      // 念のため
      this.$nextTick(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
