<template>
  <b-table
    :loading="isLoading"
    :paginated="paginated"
    :per-page="perPage"
    :striped="true"
    :default-sort="defaultSort"
    :data="rows"
  >
    <b-table-column
      label="管理番号"
      width="10em"
      field="code"
      sortable
      v-slot="{ row }"
    >
      {{ row.code }}
    </b-table-column>
    <b-table-column
      label="氏名"
      width="12em"
      field="name"
      sortable
      v-slot="{ row }"
    >
      {{ row.name }}
    </b-table-column>
    <b-table-column
      label="エリア"
      field="areaSortVal"
      sortable
      v-slot="{ row }"
    >
      {{ row.area ? row.area.label : '-' }}
    </b-table-column>
    <b-table-column label="権限" field="roleSortVal" sortable v-slot="{ row }">
      {{ row.role ? row.role.label : '-' }}
    </b-table-column>
    <b-table-column
      label="ステータス"
      width="8em"
      field="activeSortVal"
      sortable
      v-slot="{ row }"
    >
      <b-tag v-if="row.active" type="is-light">有効</b-tag>
      <b-tag v-else type="is-dark">無効</b-tag>
    </b-table-column>
    <b-table-column label="備考" field="note" sortable v-slot="{ row }">
      {{ row.note }}
    </b-table-column>
    <b-table-column
      custom-key="actions"
      cell-class="is-actions-cell"
      v-slot="{ row }"
    >
      <div class="buttons is-right">
        <b-button
          v-if="row.isShop"
          type="is-warning"
          size="is-small"
          icon-left="key"
          @click="$emit('willEditShopPassword', row)"
        />
        <b-button
          v-else
          type="is-warning"
          size="is-small"
          icon-left="square-edit-outline"
          @click="$emit('willEdit', row)"
        />
      </div>
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="isLoading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { UserTableFilter } from '@/types/typeUser'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    mUsers: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: UserTableFilter,
      default: null
    },
  },
  data () {
    return {
      perPage: 25,
      defaultSort: 'code'
    }
  },
  computed: {
    paginated () {
      return this.rows.length > this.perPage
    },
    rows () {
      if (!this.filter) {
        return this.mUsers
      }
      return this.mUsers.filter(row => this.filter.test(row))
    },
  },
}
</script>
