<template>
  <div>
    <TitleBar :title-stack="titleStack" />
    <HeroBar>
      アカウント管理
      <div class="buttons" slot="right">
        <b-button
          icon-left="plus-circle"
          type="is-info"
          size="is-small"
          @click="willCreate"
          >新規作成</b-button
        >
      </div>
    </HeroBar>
    <section class="section is-main-section">
      <CardComponent
        class="has-table has-mobile-sort-spaced"
        title="アカウント一覧"
        icon="lock"
      >
        <refresh-button slot="button" />
        <CardToolbar slot="toolbar">
          <div class="block" slot="left">
            <b-field label="エリア" class="has-check" horizontal>
              <EnumPicker
                :members="AREA_OPTIONS"
                noneLabel="全て"
                :grouped="true"
                :grouped-multiline="false"
                v-model="filter.area"
              />
            </b-field>
            <b-field label="権限" class="has-check" horizontal>
              <EnumPicker
                :members="ROLE_OPTIONS"
                noneLabel="全て"
                :grouped="true"
                :grouped-multiline="false"
                v-model="filter.role"
              />
            </b-field>
          </div>
          <div class="block" slot="right">
            <div class="field has-addons">
              <b-input v-model="filter.text" placeholder="あいまい検索" />
              <div class="control">
                <b-button type="is-light" @click="filter.text = ''"
                  ><b-icon icon="eraser" custom-size="default"
                /></b-button>
              </div>
            </div>
          </div>
        </CardToolbar>
        <UsersTable
          :isLoading="isLoading"
          :mUsers="mUsers"
          :filter="filter"
          @willEdit="willEdit"
          @willEditShopPassword="willEditShopPassword"
        />
      </CardComponent>

      <Notification class="is-info" v-if="false">
        <div>
          <b-icon icon="buffer" custom-size="default" />
          <b>Sorted and paginated table.</b>&nbsp;Based on Buefy's table.
        </div>
      </Notification>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import Notification from '@/components/Notification.vue'
import EnumPicker from '@/components/EnumPicker.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { ApiUser } from '@/api'
import UsersTable from '@/components/Users/UsersTable.vue'
import { UserTableFilter } from '@/types/typeUser'
import { Enums } from '@/types/enum'
import UserFormVue from '@/components/Users/UserForm.vue'
import { Collection } from '@/util'
import PasswordFormVue from '@/components/App/PasswordForm.vue'
export default {
  mixins: [BaseMixin],
  components: { CardToolbar, EnumPicker, RefreshButton, HeroBar, TitleBar, CardComponent, Notification, UsersTable },
  computed: {
    ROLE_OPTIONS: () => [Enums.Role.Admin, Enums.Role.Stuff, Enums.Role.Limited, Enums.Role.Shop],
    AREA_OPTIONS: () => Enums.Area.list,
    titleStack () {
      return [
        'マスタ管理', 'アカウント'
      ]
    }
  },
  data () {
    return {
      mUsers: [],
      filter: new UserTableFilter(),
    }
  },
  methods: {
    willCreate () {
      console.log('willCreate')
      this.$buefy.modal.open({
        parent: this,
        component: UserFormVue,
        hasModalCard: true,
        canCancel: false,
        // trapFocus: true,
        events: {
          saved: this.didSaved
        }
      })
    },
    willEdit (mUser) {
      console.log(mUser, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: UserFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mUser },
        events: {
          saved: this.didSaved,
          reseted: this.didReset,
        }
      })
    },
    willEditShopPassword (mUser) {
      console.log(mUser, 'willEditShopPassword')
      this.$buefy.modal.open({
        parent: this,
        component: PasswordFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { isShopPassword: true },
        events: {
          saved: () => {
            this.alertSuccess('店舗端末パスワードを更新しました')
          },
        }
      })
    },
    didSaved (mUser) {
      console.log(mUser, 'didSaved')
      this.mUsers = new Collection(this.mUsers).set(mUser).members
      this.alertSuccess('アカウント情報を更新しました')
    },
    didReset () {
      console.log('didReset')
      this.alertSuccess('パスワードをリセットしました')
    }
  },
  mounted () {
    this.isLoading = true
    setTimeout(() => {
      ApiUser.fetchUsers().then(({ mUsers }) => {
        this.mUsers = mUsers
        this.isLoading = false
      })
    }, 1000)
  }
}
</script>
